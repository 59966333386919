<template>
  <div style="height: 100%; overflow: hidden; display: flex; flex-direction: column">
    <Status></Status>
    <v-card elevation="1" tile class="pa-5" style="display: flex; align-items: center">
      <v-icon class="mr-3" style="font-size: 12px">fa-search</v-icon>
      <v-text-field label="Search" v-model="search" autofocus></v-text-field>
      <v-btn icon small @click="search = ''">
        <v-icon class="ml-3" color="red darken-2" style="font-size: 18px">fa-sync-alt</v-icon>
      </v-btn>
    </v-card>
    <div style="flex-grow: 1; overflow: auto; padding-bottom: 6em">
      <v-btn absolute dark fab right bottom color="green darken-2" style="bottom: 3%" @click="create()">
        <v-icon>fa-user-plus</v-icon>
      </v-btn>
      <v-list subheader two-line>
        <template v-for="(item, index) in items">
          <v-subheader v-if="item.header" :key="item.header" v-text="item.header"></v-subheader>
          <v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider>
          <v-list-item v-else :key="item.id">
            <v-list-item-avatar>
              <v-img :src="item.avatar"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
              <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-icon>
              <v-icon v-if="item.role == 'admin'" color="brown">fa-user-cog</v-icon>
              <v-icon v-else color="blue">fa-user-shield</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </template>
      </v-list>
    </div>
  </div>
</template>

<script>
import Status from "@/components/Status.vue";
// import { v4 as uuid } from "uuid";

export default {
  name: "People",
  components: {
    Status
  },
  created() {
    this.list();
    // this.gen();
  },
  data() {
    return {
      search: "",
      people: []
    };
  },
  computed: {
    items() {
      let list = this.people;
      if (this.search != "") {
        let s = this.search.toLowerCase();
        list = this.people.filter(person => person.title.toLowerCase().includes(s));
      }
      return list.sort((a, b) => a.title.localeCompare(b.title));
    }
  },
  methods: {
    create() {
      this.$router.push({ name: "Invite" });
    },
    async list() {
      let peeps = await this.$store.db.users.get(this.$store.device.id);
      peeps.sort((a, b) => a.title.localeCompare(b.title));
      this.people = peeps;
    },
    async gen() {
      let names = ["", "Jack Black", "Lin Wonton", "Jeff Vaugn", "Precilla Tinker", "Jody Foster", "Chris Prat"];
      let org = {};
      let dev = {};
      let grp = [];

      let orgs = await this.$store.db.organizations.get();
      let devices = await this.$store.db.devices.get();
      let groups = await this.$store.db.groups.get();

      orgs.map(item => (org[item.id] = "ADMIN_USER"));
      devices.map(item => (dev[item.id] = "ADMIN_USER"));
      groups.map(item => grp.push(item.id));

      for (let i = 1; i < names.length; i++) {
        this.$store.db.users.add({
          descpription: "720-270-9674",
          avatar: "https://cdn.vuetifyjs.com/images/lists/" + i + ".jpg",
          title: names[i],
          phone: "",
          email: "",
          devices: dev,
          organizations: org,
          groups: grp
        });
      }
    }
  }
};
</script>